import React from "react";

export const PromoSection: React.FC = (props) => {

  return (
    <section className="page-promo">
      <div className="section-content">
      {props.children}
      </div>
    </section>
  )
}